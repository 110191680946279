import { render, staticRenderFns } from "./Carrousel.vue?vue&type=template&id=3b99f23c&scoped=true&"
import script from "./Carrousel.vue?vue&type=script&lang=ts&"
export * from "./Carrousel.vue?vue&type=script&lang=ts&"
import style0 from "./Carrousel.vue?vue&type=style&index=0&id=3b99f23c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b99f23c",
  null
  
)

export default component.exports